import { render, staticRenderFns } from "./RecipientSummary.vue?vue&type=template&id=2aa2497a&"
import script from "./RecipientSummary.vue?vue&type=script&lang=ts&"
export * from "./RecipientSummary.vue?vue&type=script&lang=ts&"
import style0 from "./RecipientSummary.vue?vue&type=style&index=0&id=2aa2497a&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./_locales/common.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fcomponents%2Frecipients%2FRecipientSummary.vue"
if (typeof block0 === 'function') block0(component)
import block1 from "./_locales/RecipientSummary.json?vue&type=custom&index=1&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fcomponents%2Frecipients%2FRecipientSummary.vue"
if (typeof block1 === 'function') block1(component)
import block2 from "../_locales/Organs.json?vue&type=custom&index=2&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fcomponents%2Frecipients%2FRecipientSummary.vue"
if (typeof block2 === 'function') block2(component)
import block3 from "@/components/_locales/iposFields.json?vue&type=custom&index=3&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fcomponents%2Frecipients%2FRecipientSummary.vue"
if (typeof block3 === 'function') block3(component)

export default component.exports